import {
    action,
    computed,
    flow,
    observable
} from 'mobx';

export class VideoStore {
    root   = null;
    client = null;

    constructor(root, client) {
        this.root   = root;
        this.client = client;
    }

    create = flow(function* (gid, { id, title, kind }) {
        let video  = yield this.client.createVideo(gid, {
            id:       id,
            title:    title,
            kind:     kind,
            favorite: false,
            played:   false,
        });

        this.root.videos.push(video);
        video = this.root.videos.find(v => v.id == id);

        if (this.root.video == null) {
            this.root.selectVideo(video);
        }

        return video;
    })

    update = flow(function* (gid, video, change) {
        let update  = { ...video, ...change };
        let updated = yield this.client.updateVideo(gid, update);
        Object.assign(video, updated);
        return video;
    })

    delete = flow(function* (gid, video) {
        yield this.client.deleteVideo(gid, video);

        let videos = this.root.videos;
        let index  = videos.indexOf(video);
        videos.splice(index, 1);

        if (this.root.video == video) {
            index = index > 0 ? index - 1 : index;
            if (index < videos.length) {
                let video = videos[index];
                this.root.selectVideo(video);
            }
        }
    })
}
