export var YouTube = new Promise((resolve, reject) => {
    window.onYouTubeIframeAPIReady = () => {
        resolve(window.YT);
    };

    let script = document.createElement('script');
    script.async = true;
    script.src   = "https://www.youtube.com/iframe_api";
    script.addEventListener('error', reject);

    document.querySelector('body').appendChild(script);
});

export class YouTubeAPI {
    constructor(key) {
        this.key = key;
    }

    async video(args) {
        let part  = 'snippet';
        let query = this.query('videos', { ...args, part });
        let items = await collect(query, item => ({
            id:      item.id,
            title:   item.snippet.title
        }));
        return items;
    }

    async channel(args) {
        let part  = 'snippet,contentDetails';
        let query = this.query('channels', { ...args, part });
        let items = await collect(query, item => ({
            id:      item.id,
            title:   item.snippet.title,
            uploads: item.contentDetails.relatedPlaylists.uploads
        }));
        return items;
    }

    async playlists(args) {
        let part  = 'snippet';
        let query = this.query('playlists', { ...args, part });
        let items = await collect(query, item => ({
            id:    item.id,
            title: item.snippet.title
        }));
        return items;
    }

    async items(args) {
        let part = 'snippet';
        let query = this.query('playlistItems', { ...args, part });
        let items = await collect(query, item => ({
            id:    item.snippet.resourceId.videoId,
            title: item.snippet.title
        }));
        return items;
    }

    query(path, args) {
        let query = new URL(`https://www.googleapis.com/youtube/v3/${path}`);
        for (let [k, v] of Object.entries({ ...args, key: this.key })) {
            query.searchParams.set(k, v);
        }
        return query;
    }
}

async function collect(query, select) {
    let items = [];
    let token = "";

    query.searchParams.set('maxResults', 50);

    while (token != null) {
        query.searchParams.set('pageToken', token);

        let result = await fetch(query);
        let json   = await result.json();
        let error  = json.error;

        if (error) {
            throw error;
        }

        items.push(...(json.items || []).map(select));

        token = json.nextPageToken;
    }

    return items;
}

export const youtube = new YouTubeAPI('AIzaSyBnpmZ7a6NmXcI971fySKn2xvuRicTPQcI');
