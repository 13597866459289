import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';
import { FormModal, ModalContext } from './modal.js';
import { Container } from './manage.js';

export const UpdateGroup = observer(({ action, onCommit, group }) => {
    let modal = useContext(ModalContext);

    let [name, setName      ] = useState(group.name);
    let [loading, setLoading] = useState(false);

    const onChange = (e) => setName(e.target.value);
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        onCommit(group, { name }).then(result => {
            modal.hide();
        }).catch(modal.error);
    };

    let submit = {
        title:    action,
        disabled: name == "" || name == group.name,
    };

    return (
        <FormModal onSubmit={onSubmit} submit={submit} loading={loading}>
          <label>Name</label>
          <input type="text" autoFocus onChange={onChange} value={name}/>
        </FormModal>
    );
});

export const DeleteGroup = observer(({ onCommit, group, videos }) => {
    let modal = useContext(ModalContext);

    let [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        onCommit(group).then(result => {
            modal.hide();
        }).catch(modal.error);
    };

    let submit = {
        title: "Delete",
    };

    let count  = videos.length;
    let plural = count == 1 ? "video" : "videos";

    return (
        <FormModal onSubmit={onSubmit} submit={submit} loading={loading}>
          <div className="title">
            Delete &ldquo;{group.name}&rdquo; and {count} {plural}?
          </div>
        </FormModal>
    );
});

export const DeleteVideos = observer(({ selected, onCommit }) => {
    let modal = useContext(ModalContext);

    let [loading, setLoading] = useState(false);
    let [video,    ...videos] = selected;

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        onCommit(selected);
    };

    let submit = {
        title: "Delete",
    };

    let title  = video.title;
    let count  = videos.length;
    let plural = count == 1 ? "video" : "videos";
    let more   = "";

    if (count > 0) {
        more = ` and ${count} other ${plural}`;
    }

    return (
        <FormModal onSubmit={onSubmit} submit={submit} loading={loading}>
          <div className="title">
            Delete &ldquo;{title}&rdquo;{more}?
          </div>
        </FormModal>
    );
});

export const MoveVideos = observer(({ selected, groups, onCommit }) => {
    let store = useContext(storeContext);
    let modal = useContext(ModalContext);

    let [loading, setLoading] = useState(false);
    let [target,  setTarget ] = useState(null);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        onCommit(target, selected);
    };

    let count  = selected.length;
    let plural = count == 1 ? "video" : "videos";

    let submit = {
        title:    "Move",
        disabled: !target,
    };

    let items = store.groups.filter(g => g != store.group).map((group) => {
        let selected = group == target;
        let props = {
            key:       group.id,
            className: `group ${selected ? 'selected' : ''}`,
            onClick:   () => setTarget(group),
        };
        return <div {...props}>{group.name}</div>;
    });

    return (
        <FormModal onSubmit={onSubmit} submit={submit} loading={loading}>
          <div className="title">Move {count} {plural} to</div>
          <Container items={items}/>
        </FormModal>
    );
});
