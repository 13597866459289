import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './src/app.js';
import { Store, storeContext } from './src/store/store.js';
import './index.css';

if (window.location.hash.startsWith("#auth")) {
    let string = window.location.hash.split("=")[1];
    let result = JSON.parse(decodeURIComponent(string));
    if (window.opener && window.opener.authCallback) {
        window.opener.authCallback(result);
        window.close();
    }
}

const store = new Store();

window.store = store;

ReactDOM.render(
    <storeContext.Provider value={store}>
      <App/>
    </storeContext.Provider>,
    document.getElementById('root')
);

import { YouTubeAPI } from './src/youtube.js';
window.youtube = new YouTubeAPI('AIzaSyBnpmZ7a6NmXcI971fySKn2xvuRicTPQcI');
