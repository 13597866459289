import React, { useContext, useEffect, useState } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';
import { youtube } from './youtube.js';
import client from './client.js';
import { FormModal, ModalContext } from './modal.js';

export const AddVideo = observer(() => {
    let modal = useContext(ModalContext);
    let store = useContext(storeContext);

    let [object,   setObject  ] = useState(null);
    let [video,    setVideo   ] = useState(null);
    let [playlist, setPlaylist] = useState(null);
    let [channel,  setChannel ] = useState(null);
    let [loading,  setLoading ] = useState(false);

    const addVideo = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await store.createVideo(video);
            modal.hide();
        } catch (err) {
            modal.error({ ...err, title: video.title });
        }
    };

    const addVideos = (e) => {
        e.preventDefault();
        modal.hide();
        modal.show(<AddVideos playlist={playlist}/>);
    };

    const onChange = async (e) => {
        setLoading(true);
        try {
            let object = await resolveURL(youtube, e.target.value);
            setVideo   (object ? object.video    : null);
            setPlaylist(object ? object.playlist : null);
            setChannel (object ? object.channel  : null);
        } catch (err) {
            console.log(err);
            setVideo(null);
            setPlaylist(null);
            setChannel(null);
        } finally {
            setLoading(false);
        }
    };

    let onSubmit;
    if (video)    onSubmit = addVideo;
    if (playlist) onSubmit = addVideos;
    if (channel)  onSubmit = (e) => modal.error("FIXME: Add channel not supported");

    let submit = {
        title:    video ? "Add" : "Next",
        disabled: !video && !playlist && !channel,
    };

    return (
        <FormModal onSubmit={onSubmit} submit={submit} loading={loading}>
          <label>YouTube URL</label>
          <input type="text" autoFocus onChange={onChange}/>

          <div className="note">
            { video    && video.title    }
            { playlist && playlist.title }
          </div>
        </FormModal>
    );
});

export const AddVideos = observer(({ playlist }) => {
    let modal = useContext(ModalContext);
    let store = useContext(storeContext);

    let [videos,  setVideos ] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        youtube.items({ playlistId: playlist.id }).then(items => {
            setVideos(items.map(({ id, title }) => ({
                id, title, kind: 'youtube',
            })));
            setLoading(false);
        }).catch(modal.error);
    }, [playlist]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        await Promise.all(videos.map(video => {
            store.createVideo(video).catch(err => {
                modal.error({ ...err, title: video.title });
            });
        }));

        modal.hide();
    };

    let count  = videos.length;
    let plural = count == 1 ? "video" : "videos";
    let title  = `Add ${count} ${plural} from ${playlist.title}?`;

    return (
        <FormModal onSubmit={onSubmit} submit={{ title: "Add" }} loading={loading}>
          <div className="note">
            { !loading && title }
          </div>
        </FormModal>
    );
});

function resolveURL(youtube, str) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!str.match("^http[s]?://")) {
                resolve(null);
                return;
            }

            let id, url = new URL(str);
            switch (url.hostname) {
            case 'youtube.com':
            case 'm.youtube.com':
            case 'www.youtube.com':
                id = url.searchParams.get('v');
                break;
            case 'youtu.be':
                id = url.pathname.substring(1);
                break;
            }

            if (id) {
                let [{ title }] = await youtube.video({ id });
                if (!title) {
                    reject("Video not found.");
                    return;
                }
                resolve({ video: { id, title, kind: 'youtube' } });
                return;
            }

            let path = url.pathname.split('/');
            if (path[1] == 'user') {
                let username  = path[2];
                let channels  = await youtube.channel({ forUsername: username });
                //let playlists = await youtube.playlists({ channelId: channels[0].id });
                resolve({ channel: channels[0] });
                return;
            }

            if (path[1] == 'channel') {
                let id       = path[2];
                let channels = await youtube.channel({ id });
                resolve({ channel: channels[0] });
                return;
            }

            if (path[1] == 'playlist') {
                let id        = url.searchParams.get('list');
                let playlists = await youtube.playlists({ id });
                resolve({ playlist: playlists[0] });
                return;
            }

            resolve(null);
        } catch (err) {
            reject(err);
        }
    });
}

// await window.resolveURL("https://www.youtube.com/playlist?list=PLQvJhrHQe8uc7dyB1S_jXBpzVgTjsJ55A")
// await window.resolveURL("https://www.youtube.com/channel/UCRMxcpgVb1Lo4r5tiRQkjkA")
// await window.resolveURL("https://www.youtube.com/user/Angel65Pain")

window.resolveURL = (str) => {
    return resolveURL(youtube, str);
};

function lookupVideo(str) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!str.match("^http[s]?://")) {
                resolve(null);
                return;
            }

            let id, url = new URL(str);
            switch (url.hostname) {
            case 'youtube.com':
            case 'm.youtube.com':
            case 'www.youtube.com':
                id = url.searchParams.get('v');
                break;
            case 'youtu.be':
                id = url.pathname.substring(1);
                break;
            }

            if (!id) {
                resolve(null);
                return;
            }

            let items = await youtube.video({ id });

            if (items.length == 0) {
                reject("Video not found.");
                return;
            }

            let title = items[0].title;

            resolve({ id, title, kind: 'youtube' });
        } catch (err) {
            reject(JSON.stringify(err, null, 2));
        }
    });
}
