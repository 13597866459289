import React, { useContext } from 'react';
import * as icon from './icons.js';
import { Player } from './player.js';
import { Videos } from './videos.js';
import { AddVideo } from './add.js';
import { ModalContext } from './modal.js';

export const Watch = () => {
    let modal = useContext(ModalContext);

    const showAdd = () => modal.show(<AddVideo/>);

    return (
        <div className="watch">
          <div className="a">
            <Player/>
          </div>

          <Videos/>

          <button className="add" onClick={showAdd}><icon.AddVideo/></button>
        </div>
    );
};
