import React, { useContext, useEffect, useState } from 'react';
import { action } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import Spinner from 'react-svg-spinner';
import { storeContext } from './store/store.js';
import { uuidv4 } from './uuid.js';
import { DeleteGroup, UpdateGroup, DeleteVideos, MoveVideos } from './update.js';
import { AddVideo } from './add.js';
import { ModalContext } from './modal.js';
import * as icon from './icons.js';

export const Manage = observer(() => {
    let store = useContext(storeContext);
    let modal = useContext(ModalContext);

    let [loading, setLoading] = useState(false);

    useEffect(() => {
        let e = document.querySelector('.group.selected');
        e && e.scrollIntoView();
    });

    const createGroup = () => {
        let group  = { id: uuidv4(), name: "" };
        let commit = (group, change) => store.createGroup({ ...group, ...change });
        modal.show(<UpdateGroup action="Add" group={group} onCommit={commit}/>);
    };

    const updateGroup = () => {
        let group  = store.group;
        let commit = (group, change) => store.updateGroup(group, change);
        modal.show(<UpdateGroup action="Update" group={group} onCommit={commit}/>);
    };

    const deleteGroup = () => {
        let props = {
            group:    store.group,
            videos:   store.videos,
            onCommit: (group) => store.deleteGroup(group),
        };

        modal.show(<DeleteGroup {...props}/>);
    };

    const selectGroup = (group) => {
        setLoading(true);
        store.selectGroup(group).finally(() => {
            setLoading(false);
        });
    };

    let items = store.groups.map((group) => {
        let selected = store.group == group;
        let onClick  = loading ? null : () => selectGroup(group);

        let props = {
            key:       group.id,
            disabled:  loading,
            className: `group ${selected ? 'selected' : ''}`,
            onClick:   onClick,
        };

        return <div {...props}>{group.name}</div>;
    });

    let createOk = !loading;
    let updateOk = !loading && store.group != null;
    let deleteOk = updateOk && store.groups.length > 1;

    return (
        <div className="manage">
          <Container items={items}>
            <button disabled={!createOk} onClick={createGroup}><icon.Add/></button>
            <button disabled={!updateOk} onClick={updateGroup}><icon.Edit/></button>
            <span></span>
            <button disabled={!deleteOk} onClick={deleteGroup}><icon.Delete/></button>
          </Container>

          <Videos loading={loading}/>
        </div>
    );
});

export const Videos = observer(({ loading }) => {
    let store = useContext(storeContext);
    let modal = useContext(ModalContext);

    let state = useLocalStore(() => ({
        selected:   [],
        isSelected: (video) => state.selected.includes(video),
        toggle:     action(function (video) {
            let index = state.selected.indexOf(video);
            (index == -1) ? state.selected.push(video) : state.selected.splice(index, 1);
        }),
        clear: action(function () { state.selected = []; }),
    }));

    const createVideo = () => modal.show(<AddVideo/>);
    const moveVideos  = () => {
        let commit = async (group) => {
            throw "can't do it";
        };

        let props = {
            selected: state.selected,
            onCommit: commit
        };

        modal.show(<MoveVideos {...props}/>);
    };

    const deleteVideos = () => {
        let commit = async (videos) => {
            try {
                await Promise.all(videos.map(store.deleteVideo));
                state.clear();
                modal.hide();
            } catch (err) {
                modal.error(err);
            }
        };

        let props = {
            selected: state.selected,
            onCommit: commit
        };

        modal.show(<DeleteVideos {...props}/>);
    };

    let items = store.videos.map((video) => {
        let selected = state.isSelected(video);
        let props = {
            key:       video.id,
            className: `video ${selected ? 'selected' : ''}`,
            onClick:   () => state.toggle(video),
        };
        return <div {...props}>{video.title}</div>;
    });

    let createOk = !loading;
    let moveOk   = !loading && state.selected.length > 0;
    let deleteOk = !loading && state.selected.length > 0;

    return (
        <Container loading={loading} items={items}>
          <button disabled={!createOk} onClick={createVideo}><icon.Add/></button>
          <button disabled={!moveOk  } onClick={moveVideos}><icon.Move/></button>
          <span></span>
          <button disabled={!deleteOk} onClick={deleteVideos}><icon.Delete/></button>
        </Container>
    );
});

export const Container = ({ items, loading, children }) => {
    if (loading) {
        items = <div className="spinner">Loading <Spinner/></div>;
    }

    return (
        <div className="container">
          <div className="list">
            { items }
          </div>

          <div className="buttons">
            { children }
          </div>
        </div>
    );
};
