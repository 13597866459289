import React, { useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { observer } from 'mobx-react-lite';
import Spinner from 'react-svg-spinner';
import { storeContext } from './store/store.js';
import { Header, VIDEO, GROUP, CONFIG } from './header.js';
import { ErrorModal, Loading, Modal, ModalContext } from './modal.js';
import { Welcome } from './welcome.js';
import { Manage } from './manage.js';
import { Watch } from './watch.js';
import { Config } from './config.js';
import client from './client.js';

const App = observer(() => {
    let store = useContext(storeContext);

    let [active, setActive] = useState(VIDEO);
    let [modals, setModals] = useState([<Wait/>]);

    const show  = (modal) => setModals(modals => modals.concat(modal));
    const hide  = ()      => setModals(modals => modals.slice(0, -1));
    const error = (err)   => show(<ErrorModal error={err}/>);

    let context = { show, hide, error };

    useEffect(() => {
        store.authenticate().then(account => {
            setModals([]);
        }).catch(err => {
            setModals([<SignIn/>, <Welcome/>]);
        });
    }, []);

    let main;
    switch (active) {
    case VIDEO:
        main = <Watch/>;
        break;
    case GROUP:
        main = <Manage/>;
        break;
    case CONFIG:
        main = <Config/>;
        break;
    }

    return (
        <ModalContext.Provider value={context}>
          <Header onClick={setActive} active={active}/>
          {main}
          {modals.slice(-1)[0]}

          {/* <pre> */}
          {/*   Group: { JSON.stringify(store.group || {}, null, '  ') } */}
          {/*   Video: { JSON.stringify(store.video || {}, null, '  ') } */}
          {/* </pre> */}
        </ModalContext.Provider>
    );
});

const Wait = () => (
    <div className="overlay">
      <div className="loading">
        <span>Loading</span>
        <Spinner/>
      </div>
    </div>
);

const SignIn = () => {
    let modal = useContext(ModalContext);
    let store = useContext(storeContext);

    let [loading, setLoading] = useState(false);

    let why    = () => modal.show(<Welcome page={1}/>);
    let signin = async () => {
        setLoading(true);
        try {
            let base = window.origin;
            await redirectAndWait(base + "/oauth2/redirect");
            await store.authenticate();
            modal.hide();
        } catch (error) {
            console.log(error);
            modal.error(error);
        }
    };

    let props = {
        disabled: loading,
        onClick:  signin,
    };

    return (
        <Modal>
          <div style={{alignItems: "center"}}>
            <p>Welcome to Mivvy!</p>

            <div className="content signin">
              {!loading && <button  { ...props }>Sign in with Google</button> }
              { loading && <Loading { ...props }>Sign in with Google</Loading> }
            </div>

            <p><a onClick={why}>Why sign in?</a></p>
          </div>
        </Modal>
    );
};

const redirectAndWait = (url) => {
    return new Promise((resolve, reject) => {
        window.authCallback = ({ status, reason: error }) => {
            switch (status) {
            case "ok":
                resolve();
            case "error":
                reject({ error });
            }
        };

        let child = window.open(url);

        let timer = window.setInterval(() => {
            if (child.closed) {
                window.clearInterval(timer);
                reject({ error: 'window-closed' });
            }
        }, 100);
    });
};

export default hot(App);
