import React, { useContext, useState } from 'react';
import { TextModal, ModalContext } from './modal.js';

export const Welcome = ({ page }) => {
    let modal = useContext(ModalContext);

    let [active, setActive] = useState(page || 0);

    let view, text, next;
    switch (active) {
    case 0:
        view = <Page0/>;
        text = "Next";
        next = () => setActive(1);
        break;
    case 1:
        view = <Page1/>;
        text = "Done";
        next = modal.hide;
        break;
    }

    return (
        <TextModal>
          {view}

          <div className="buttons">
            <a onClick={() => modal.hide()}>Skip</a>
            <button type="button" onClick={next}>{text}</button>
          </div>
        </TextModal>
    );
}

const Page0 = () => (
    <div>
      <h1>Welcome!</h1>

      <p>
        Welcome to Mivvy, your personal video management system. Mivvy
        provides an easy and touch-friendly interface to save, organize,
        and watch the YouTube videos you’re interested in.
      </p>

      <p>
        Mivvy’s distraction-free player keeps track of your current
        position in each video making it easy to pause and resume as
        desired, even days later. Videos can be grouped into playlists
        for convenient and easy transition between topics.
      </p>

      <p>
        Mivvy keeps your video data private and does not display ads or
        share your information with anyone. However YouTube videos often
        contain ads, which is outside of Mivvy’s control.
      </p>
    </div>
);

const Page1 = () => (
    <div>
      <h1>Some Details</h1>

      <p>
        Mivvy requires you to sign in with your Google account. Your
        Google identity is used only to keep track of your videos and
        make them available on any of your devices.
      </p>

      <p>
        Mivvy’s free tier allows you to store up to 10 videos in a single
        playlist. Purchasing a subscription allows for unlimited videos
        and playlists, and supports the cost of keeping Mivvy running and
        ad-free.
      </p>

      <p>
        Mivvy only supports YouTube videos at the moment, but other video
        sources are being considered and plain video URLs may be supported
        as well.
      </p>

      <p>
        Thank you for checking Mivvy out! We find it super useful and hope
        you do too.
      </p>
    </div>
);
