import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';

export const Videos = observer(() => {
    let store = useContext(storeContext);

    useEffect(() => {
        let e = document.querySelector('.video.selected');
        e && e.scrollIntoView();
    }, [store.group]);

    let items = store.videos.map((video) => {
        let selected = video == store.video;
        let props = {
            key:       video.id,
            className: `video ${selected ? 'selected' : ''}`,
            onClick:   () => store.selectVideo(video),
        };
        return <div {...props}>{video.title}</div>;
    });

    return (
        <div className="container">
          <div className="list">
            {items}
          </div>
        </div>
    );
});
