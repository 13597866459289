import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';
import { YouTube  }from './youtube.js';
import { Controls } from './controls.js';

export const LOADING = Symbol.for('loading');
export const READY   = Symbol.for('ready');
export const PLAYING = Symbol.for('playing');
export const PAUSED  = Symbol.for('paused');

export const Player = observer(() => {
    let store = useContext(storeContext);

    let ref = useRef();

    let [state,  setState ] = useState(LOADING);
    let [player, setPlayer] = useState(null);

    const onReady = (e) => {
        let player = e.target;
        setState(READY);
        setPlayer(player);
    };

    const onStateChange = (playerStates, { data }) => {
        switch (data) {
        case playerStates.UNSTARTED:
            break;
        case playerStates.PLAYING:
            setState(PLAYING);
            break;
        case playerStates.PAUSED:
            setState(PAUSED);
            break;
        case playerStates.ENDED:
            store.updateVideo(store.video, {
                played:   true,
                position: 0,
            });
            setState(READY);
            // FIXME: setting
            // this.next();
            break;
        case playerStates.BUFFERING:
            break;
        case playerStates.CUED:
            setState(READY);
            break;
        }
    };

    useEffect(() => {
        YouTube.then(youtube => {
            let playerStates = youtube.PlayerState;
            new youtube.Player(ref.current, {
                events: {
                    onReady:       onReady,
                    onError:       (e) => console.log(e),
                    onStateChange: (e) => onStateChange(playerStates, e),
                }
            });
        }).catch(console.error);
    }, []);

    const fullscreen = () => {
        let frame = ref.current;
        frame.width  = window.screenWidth;
        frame.height = window.screenHeight;
        frame.webkitRequestFullScreen();
    };

    let video  = store.video;
    let base   = "https://www.youtube.com/embed/";
    let origin = window.location.origin;
    let url    = `${base}?enablejsapi=1&origin=${origin}`;

    return (
        <>
          <div className="player">
            <iframe
              ref         = {ref}
              src         = {url}
              frameBorder = {0}
              allow       = "*"
            />
          </div>

          <Controls player={player} state={state} fullscreen={fullscreen}/>

          <h1>{video && video.title}</h1>

          {/* <div> */}
          {/*   {video && this.props.cursor.position > 0 && `${Math.floor(this.props.cursor.position / 60)}:${Math.floor(this.props.cursor.position % 60)}`} */}
          {/* </div> */}
        </>
    );
});
