import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';
import client from './client.js';

export const Config = observer(() => {
    let store = useContext(storeContext);
    let account = store.account;

    let signout = () => {
        client.signout();
        window.location = window.location;
    };

    return (
        <div>
          <fieldset>
            <legend>Account</legend>
            <div><label>Email</label><span>{ account.email }</span></div>
            <div><label>Subscription</label><span>Free</span></div>
                <div><label></label><button onClick={signout}>Sign Out</button></div>
          </fieldset>
        </div>
    );
});
