const GET    = 'GET';
const DELETE = 'DELETE';
const POST   = 'POST';
const PUT    = 'PUT';

export class Client {
    account = () => this.do({
        method: 'POST',
        path:   `/api/account`,
    });

    updateAccount = (account) => this.do({
        method: 'PUT',
        path:   `/api/account`,
        body:    account,
    });

    signout = () => this.do({
        method: 'PUT',
        path:   `/api/signout`,
    });

    groups = () => this.do({
        method: 'GET',
        path:   `/api/groups`,
    });

    videos = (gid) => this.do({
        method: 'GET',
        path:   `/api/groups/${gid}/videos`,
    });

    getGroup = (gid) => this.do({
        method: 'GET',
        path:   `/api/groups/${gid}`,
    });

    createVideo = (gid, video) => this.do({
        method: 'POST',
        path:   `/api/groups/${gid}/videos/${video.id}`,
        body:   video
    });

    deleteVideo = (gid, video) => this.do({
        method: 'DELETE',
        path:   `/api/groups/${gid}/videos/${video.id}`,
        body:   video
    });

    updateVideo = (gid, video) => this.do({
        method: 'PUT',
        path:   `/api/groups/${gid}/videos/${video.id}`,
        body:   video
    });

    createGroup = (group) => this.do({
        method: 'POST',
        path:   `/api/groups/${group.id}`,
        body:   group,
    });

    deleteGroup = (group) => this.do({
        method: 'DELETE',
        path:   `/api/groups/${group.id}`,
        body:   group
    });

    updateGroup = (group) => this.do({
        method: 'PUT',
        path:   `/api/groups/${group.id}`,
        body:   group
    });

    do({ method, path, body }) {
        return new Promise(async (resolve, reject) => {
            let r = await fetch(path, {
                method,
                headers: {
                    'Content-Type':  'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!r.ok) {
                reject({code: r.status, text: r.statusText});
                return;
            }

            resolve(r.json());
        });
    }
}

const client = new Client();

//FIXME: remove
window.client = client;

export default client;
