import React from 'react';
import { Video, Group, Config } from './icons.js';

export const VIDEO  = Symbol.for('video');
export const GROUP  = Symbol.for('group');
export const CONFIG = Symbol.for('config');

export const Header = ({ onClick, active }) => {
    const onVideoClick  = () => onClick(VIDEO);
    const onGroupClick  = () => onClick(GROUP);
    const onConfigClick = () => onClick(CONFIG);

    return (
        <div className="header">
          <h1>Mivvy</h1>

          <div>
            <Button onClick={onVideoClick}  active={active == VIDEO }><Video/></Button>
            <Button onClick={onGroupClick}  active={active == GROUP }><Group/></Button>
            <Button onClick={onConfigClick} active={active == CONFIG}><Config/></Button>
          </div>
        </div>
    );
};

const Button = ({ onClick, active, children }) => {
    let props = {
        className: `${active ? 'active' : ''}`,
        onClick:    onClick
    };
    return <button {...props}>{children}</button>;
};
