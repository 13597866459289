import {
    action,
    computed,
    flow,
    observable
} from 'mobx';

export class GroupStore {
    root   = null;
    client = null;

    constructor(root, client) {
        this.root   = root;
        this.client = client;
    }

    create = flow(function* ({ id, name }) {
        let group  = yield this.client.createGroup({
            id:     id,
            name:   name,
            videos: [],
        });
        this.root.groups.push(group);
        return group;
    })

    update = flow(function* (group, change) {
        let update  = { ...group, ...change };
        let updated = yield this.client.updateGroup(update);
        Object.assign(group, updated);
        return group;
    })

    delete = flow(function* (group) {
        yield this.client.deleteGroup(group);

        let groups = this.root.groups;
        let index = groups.indexOf(group);
        groups.splice(index, 1);

        if (this.root.group == group) {
            let count = groups.length;
            this.root.selectGroup(groups[count - 1]);
        }
    })
}
