import React, { useContext, useEffect } from 'react';
import Spinner from 'react-svg-spinner';

export const ModalContext = React.createContext({
    show:  ()    => {},
    hide:  ()    => {},
    error: (err) => {},
});

const consume = (e) => e.stopPropagation();

export const Modal = ({ className, onOverlayClick, children }) => {
    return (
        <div className="overlay" onClick={onOverlayClick}>
          <div className="modal" onClick={consume}>
            {children}
          </div>
        </div>
    );
};

export const TextModal = ({ children }) => (
    <Modal>
      <div className="content text">
        { children }
      </div>
    </Modal>
);

export const FormModal = ({ onSubmit, submit, loading, children }) => {
    let { hide } = useContext(ModalContext);

    useShortcutKeys();

    return (
        <Modal onOverlayClick={hide}>
          <form onSubmit={onSubmit}>
            <div className="content">
              { children}
            </div>

            <div className="buttons">
              <button type="button" disabled={loading} onClick={hide}>Cancel</button>
              {!loading && <button disabled={submit.disabled}>{ submit.title }</button> }
              { loading && <Loading>{ submit.title }</Loading> }
            </div>
          </form>
        </Modal>
    );
};

export const ErrorModal = (error) => {
    let { hide } = useContext(ModalContext);
    return (
        <Modal onOverlayClick={hide}>
          <div className="content">
            { format(error) }
          </div>

          <div className="buttons">
            <button type="button" onClick={hide}>Ok</button>
          </div>
        </Modal>
    );
};

export const Loading = ({ children, ...props }) => (
    <button disabled className="loading" { ...props }>
      <span>{ children}</span>
      <Spinner color="#FAFAFA"/>
    </button>
);

const format = (err) => {
    console.log("typeof err", typeof(err), err);

    if (err.error) {
        let { code, title } = err.error;
        switch (code) {
        case 409:
            return `Video "${title}" already added.`;
        }
    }

    switch (typeof(err)) {
    case "string":
        return err;
    default:
        return JSON.stringify(err, null, "  ");
    }
};

const useShortcutKeys = () => {
    let { hide } = useContext(ModalContext);
    useEffect(() => {
        let listener = window.addEventListener("keydown", (e) => {
            if (e.keyCode == 27) {
                hide();
            }
        });
        return () => window.removeEventListener("keydown", listener);
    }, []);
};
