import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { storeContext } from './store/store.js';
import { LOADING, PLAYING } from './player.js';
import * as icon from './icons.js';

export const Controls = observer(({ player, state, fullscreen }) => {
    let store = useContext(storeContext);

    let video = store.video;
    let next  = video ? store.nextVideo(video) : null;
    let prev  = video ? store.prevVideo(video) : null;

    useEffect(() => {
        if (player && video) {
            console.log("cue video", video.id);
            player.cueVideoById(video.id, video.position);
        }
    }, [player, video]);

    useUpdatePosition(player, state, (position) => {
        store.updateVideo(video, { position });
    }, 30 * 1000);

    const play      = () => player.playVideo();
    const pause     = () => player.pauseVideo();
    const replay    = () => player.seekTo(player.getCurrentTime() - 10, true);
    const forward   = () => player.seekTo(player.getCurrentTime() + 10, true);
    const nextVideo = () => store.selectVideo(next);
    const prevVideo = () => store.selectVideo(prev);
    const remove    = () => store.deleteVideo(video);

    let disabled = !video || state == LOADING;
    let played   = !video || !video.played;

    let action;
    if (state == PLAYING) {
        action = <Button disabled={disabled} onClick={pause}><icon.Pause/></Button>;
    } else {
        action = <Button disabled={disabled} onClick={play}><icon.Play/></Button>;
    }

    return (
        <div className="controls">
          <Button disabled={disabled || !prev} onClick={prevVideo}><icon.Previous/></Button>
          <Button disabled={disabled} onClick={replay}><icon.Replay10/></Button>
          {action}
          <Button disabled={disabled} onClick={forward}><icon.Forward10/></Button>
          <Button disabled={disabled || !next} onClick={nextVideo}><icon.Next/></Button>
          <span></span>
          <Button disabled={played}><icon.Done/></Button>
          <Button disabled={disabled} onClick={remove}><icon.Delete/></Button>
          <Button disabled={disabled} onClick={fullscreen}><icon.FullScreen/></Button>
        </div>
    );
});

const Button = ({children, ...rest}) => (
    <button {...rest}>
      {children}
    </button>
);

const useUpdatePosition = (player, state, callback, interval) => {
    useEffect(() => {
        let timer;
        if (state == PLAYING) {
            timer = setInterval(() => {
                let position = player.getCurrentTime();
                callback(Math.floor(position));
            }, interval);
        }
        return () => clearInterval(timer);
    }, [player, state]);
};
