import { createContext } from 'react';
import {
    action,
    computed,
    configure,
    flow,
    observable,
} from 'mobx';
import { Client } from '../client.js';
import { GroupStore } from './groups.js';
import { VideoStore } from './videos.js';

configure({ enforceActions: "observed" });

export class Store {
    @observable account = null;
    @observable group   = null;
    @observable video   = null;
    @observable groups  = [];
    @observable videos  = [];

    client = new Client();
    vstore = null;
    gstore = null;

    authenticate = flow(function* () {
        let { account, groups, videos } = yield this.client.account();

        this.groups = groups;
        this.videos = videos;

        this.gstore = new GroupStore(this, this.client);
        this.vstore = new VideoStore(this, this.client);

        this.account = account;
        this.group   = this.groups.find(g => g.id == account.group);
        this.video   = this.videos.find(v => v.id == account.video);

        return account;
    })

    signout = flow(function* () {
        yield this.client.signout();
        this.account = null;
    })

    selectGroup = flow(function* (group) {
        this.group  = group;
        this.videos = yield this.client.videos(group.id);

        let video = this.videos.find(v => v.id == group.video);
        video = video || this.videos[0] || {};

        this.account = yield this.client.updateAccount({
            ...this.account,
            video: video.id,
            group: group.id,
        });

        this.video  = video;
    })

    selectVideo = flow(function* (video) {
        this.video = video;
        this.group.video = video.id;

        let account = yield this.client.updateAccount({
            ...this.account,
            video: video.id,
        });

        this.account = account;
    })

    createGroup = (group)         => this.gstore.create(group);
    updateGroup = (group, change) => this.gstore.update(group, change);
    deleteGroup = (group)         => this.gstore.delete(group);

    createVideo = (video)         => this.vstore.create(this.group.id, video);
    updateVideo = (video, change) => this.vstore.update(this.group.id, video, change);
    deleteVideo = (video)         => this.vstore.delete(this.group.id, video);

    nextVideo = (video) => {
        let index = this.videos.indexOf(video) + 1;
        return index < this.videos.length ? this.videos[index] : null;
    }

    prevVideo = (video) => {
        let index = this.videos.indexOf(video) - 1;
        return index >= 0 ? this.videos[index] : null;
    }
}

export const storeContext = createContext();
